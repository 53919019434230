import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';

import './ContactUsPage.scss';

const ContactUs: FC<ContactUsPageTypes.ContactUsProps> = ({
  data: {
    contactUs: { urls, seo, title, description },
  },
}) => (
  <Layout>
    <Seo {...{ urls, seo }} />
    <PageSchema
      type="WebPage"
      name={seo.title}
      data={{
        metaTitle: seo.title,
        metaDescription: seo.description,
        metaKeywords: seo.keywords,
      }}
    />
    <Container className="contact-us">
      <h1 className="contact-us__heading">{title}</h1>
      <div className="contact-us__desc-wrapper">
        {description.map((desc) => (
          <div className="contact-us__desc">
            <div className="contact-us__image-wrapper">
              {desc.image ? <Image {...desc.image} className="contact-us__image" /> : null}
              <Typography dangerouslySetInnerHTML={desc.title} className="contact-us__title" />
            </div>
            <Typography dangerouslySetInnerHTML={desc.description} className="contact-us__data" />
          </div>
        ))}
      </div>
    </Container>
  </Layout>
);

export const query = graphql`
  {
    contactUs {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      pageName
      title
      description {
        title
        image {
          ...TransparentImageStructureFragment
        }
        description
      }
    }
  }
`;

export default ContactUs;
